.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
}

.result-block-container .result-block {
  opacity: 1;
}

.top-logo{
  margin-bottom: 100px!important;
}
/* li.nav-item{
  // (Conflict with Mobile Menu > 'Find services' item width
  display: inline-block;
} */
.action-link{
  text-decoration: underline;
  cursor: pointer;
}

.choices label a {
  display: inline;
  margin-left: 2px;
}
