body {
  margin: 0;
  padding: 0;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.tc-decline-text{
  min-height: 60px;
  padding:20px;
}
.btn-footer .actions{
  padding:0 50px;
}
.btn-title{
  height: 40px;
  padding: 10px;
}
@media (min-width: 992px) {
  #profileDropDown {
    min-width: 150px;
    text-align: 'right';
  }
}
#profileDropDown+.dropdown-menu {
  width: 100%;
  display: none;
  }
  
  #profileDropDown+.dropdown-menu.show {
    display: block;
}

#qsLogoutBtn{
  float: right;
}

@media (max-width: 991px) {
  #qg-site-nav.show #profileDropDown+.dropdown-menu {
      display: block;
    }
  #profileDropDown::after {
    visibility: hidden;
  }

  #profileDropDown+.dropdown-menu {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  #profileDropDown+.dropdown-menu,
  #profileDropDown+.dropdown-menu:hover {
    background-color: transparent;
  }

  #profileDropDown+.dropdown-menu:hover {}

  #qsLogoutBtn {
    height: 100%;
    text-align: right;
  }

  #qsLogoutBtn:hover {
    background-color: transparent;
  }

}