#qg-content[style] {
  display: block !important;
}

@media (max-width: 320px) {
  #qg-site-header .row > :nth-child(3) {
    display: none;
  }
}

@media (max-width: 991px) {
  .qg-coat-of-arms {
    margin: 0 auto;
  }
  
  .alignment-placeholder {
    width: 56px;
  }
}

#content-loading {
  min-width: 170px;
  min-height: 200px;
  user-select: none;
}

@media (min-width: 992px) {
  #content-loading {
    min-height: 300px;
  }
}

.spinner-border.hidden {
  display: none;
}

.content-scrollable-vh40 {
  height: 40vh;
  overflow: auto;
  resize: vertical;
}
.content-scrollable-vh40.fullheight {
  height: 100%;
  overflow: visible;
}

/* #### styles specific to cards #### */

.qg-card.qg-card__clickable {
  cursor: pointer;
}

.qg-card.qg-card__clickable.active,
.qg-card.qg-card__clickable.active a {
  cursor: default !important;
}
.qg-card.qg-card__clickable.disabled,
.qg-card.qg-card__clickable.disabled a {
  cursor: not-allowed !important;
}

/* .qg-card.qg-card__clickable .content:focus-within */
  .qg-card.active .content,
  .qg-card.active .content:focus-within,
  .qg-card.disabled .content,
  .qg-card.disabled .content:focus-within {
    box-shadow: none;
  }
  .qg-card.qg-card__clickable.active .content,
  .qg-card.qg-card__clickable.disabled .content {
    opacity: 0.8;
  }

  .card-content-wrap {
    display: flex;
    flex-flow: row-reverse nowrap;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    gap: 20px;
    min-height: 140px; /* alternate solution to js onload resizing issue when parent has class `section.cards__equal-height` */
  }

  .card-id-type-img {
    min-width: 120px;
  }
  .card-id-type-text {
    flex: 2;
    margin-top: 0 !important;
  }
  h4.qg-card__title { 
    margin-bottom: 0;
    font-weight: 600; 
  }

  .qg-card .content .details>:first-child {
    padding-right: 0;
  }

  .qg-card .content img.id-type-img {
    width: auto;
    max-width: 140px;
    max-height: 145px;
    margin: 0 auto;
  }

  .qg-card.qg-card__light-theme .content .details .stretched-link,
  .qg-card.qg-card__light-theme .content .details .stretched-link:visited {
    color: #000 !important;
  }

  /*   
  .qg-card .content.card-selected {
    background-color:#fbfbfb;
    border-color:#005e85;
  }
  .qg-card__clickable .content.card-selected {
    box-shadow: 0 2px 4px rgb(0 0 0 / 90%);
  }
  */

  .initial-question label[for='state-of-issue'] em.alert,
  .initial-question label[for='registration-state'] em.alert { display: none; }

  ul.selection-group li {
    padding-bottom: 0;
  }

  .selection-group .choice-display {
    padding: 0.5em 0 0;
    font-weight: bold;
    margin-top: 10px;
  }

  fieldset[id] {
    position: relative;
  }
  fieldset[id] legend h2.label {
    margin: 0 0 10px;
    font-weight: 800;
  }
  fieldset[id] legend span.label em {
    font-style: normal;
  }

  @-moz-document url-prefix() {
    fieldset[id] legend abbr.required {
      margin-top: -20px;
    }
  }

  .alt-required span.label {
    display: inline-block;
  }
  .alt-required abbr.required {
    /* display: inline-block; */
    display: none;
    margin-left: 0.15em;

  }

  @media screen and (max-width: 350px) {
  
    .card-content-wrap {
        flex-flow: column nowrap;
        justify-content: center;
        gap: 1rem;
    }
  
    .qg-card .content .details>:nth-child(2) {
      margin-top: 0;
    }
  
    .card-id-type-img {
      margin: 0.5rem 0.5rem 0 !important;
    }

    .qg-card__title {
      max-width: 100%;
      text-align: center;
    }
  
  }

  @media screen and (max-width: 240px) {
    .card-id-type-img {
      min-width: auto;
    }
    .qg-card .content img.id-type-img {
      width: 100%;
    }    
  }
  
  /* #### styles specific to cards - end #### */
  
  #qg-primary-content a.qg-btn.btn-outline-dark,
  #qg-primary-content a.qg-btn.btn-outline-dark:visited,
  .qg-btn.btn-outline-dark:visited {
    color: #00698f;
    text-decoration: none !important;
  }
  /* ### End qg style overrides ## */

.error-label{
    color:#d93d31;
    display:block;
    margin-bottom: 5px;
}

label .hint {
  margin-top: 5px;
  margin-bottom: 5px;
}

input + .hint,
select + .hint {
  margin-top: 5px;
}


input[type=date].input-alert,
input[type=datetime].input-alert,
input[type=email].input-alert,
input[type=number].input-alert,
input[type=password].input-alert,
input[type=search].input-alert,
input[type=tel].input-alert,
input[type=text].input-alert,
input[type=time].input-alert,
input[type=url].input-alert,
.qg-forms-v2 select.form-control.input-alert {
    border:3px solid #dc3545;
}
  

.list-style-none {
  list-style: none;
}

.no-top-bottom-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.callout-box {
  padding: 10px;
  background-color: #eff5f6;
  border: none;
}

/*
.action-link {
  color: #13578b !important;
}
*/

.bgc6f777b {
  background-color: #6f777b;
}

.bgcf8f9fa {
  background-color: #f8f9fa;
}

@media (max-width: 600px) {
  .qg-site-footer .col:last-child {
    padding: 0;
  }
}

.hybrid-datepicker {
  position: absolute;
  top: 3px;
  right: 3px;
  height: 38px !important;
  width: 30%;
  border: 0px !important;
  box-shadow: none;
}

.qdi-date-input {
  border: 2px solid #818181 !important;
}

.qdi-date-seperator {
  padding: 10px;
  max-width: fit-content;
  color: #818181;
}

.qdi-date-input-wrapper {
  padding: 0;
  max-width: fit-content;
}