#content-privacy {
}

.sandbox#content-privacy * {
  margin: 0;
  font-size: 1rem;
}

.sandbox#content-privacy h2,
.sandbox#content-privacy h3,
.sandbox#content-privacy h4,
.sandbox#content-privacy h5,
.sandbox#content-privacy h6 {
  margin: 0;
  font-size: 1rem;
}

.sandbox#content-privacy h1 {
  margin: 20px 0 10px;
  font-size: 20px;
}
.sandbox#content-privacy h1:first-child {
  margin-top: 0;
}

.sandbox#content-privacy h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 800;
  font-size: 1.1rem;
  border-bottom: solid 1px #000;
}
.sandbox#content-privacy h3 {
  margin-bottom: 10px;
  font-weight: 600;
}

.sandbox#content-privacy h4 {
  margin-top: 20px;
}
.sandbox#content-privacy h5 {
  margin: 10px 0;
  font-weight: 600;
}

.sandbox#content-privacy h6 {
  font-weight: normal;
}

.sandbox#content-privacy ol,
.sandbox#content-privacy ul {
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  padding-left: 0px;
  list-style: none;
}

.sandbox#content-privacy li {
  clear: both;
  margin-bottom: 10px;
}

.sandbox#content-privacy span.li-num {
  display: inline-block;
  margin-right: 5px;
  min-width: 20px;
}

.sandbox#content-privacy ol[type='1'] {  
  padding-top: 10px;
}
.sandbox#content-privacy ol[type='1'] > li {
  margin-bottom: 20px;
}

.sandbox#content-privacy ol[type='a'] {
  margin-bottom: 20px;
  padding-top: 10px;
  padding-left: 5px;
}

.sandbox#content-privacy ol[type='1'] span.li-num,
.sandbox#content-privacy ol[type='a'] span.li-num {
  font-weight: 600;
}

.sandbox#content-privacy ol[type='i'] span.li-num {
  font-weight: 300;
}

.sandbox#content-privacy ol[type='i'] {
  padding-top: 10px;
}
.sandbox#content-privacy ol[type='i'] > li {
  padding-left: 20px;
}

.sandbox#content-privacy dl {
  margin-top: 10px;
}
.sandbox#content-privacy dl:after {
  content: "";
  display: table;
  clear: both;
}

.sandbox#content-privacy dt {
  clear: both;
  float: left;
  min-width: 120px;
  margin-right: 10px;
  margin-top: 5px;
  font-weight: 800;
  line-height: 1.0rem;
}
.sandbox#content-privacy dd {
  margin-bottom: 10px;
  padding-left: 130px;  
  font-weight: 400;
}

.sandbox#content-privacy address {
  margin-bottom: 20px;
  font-weight: 400;
}

.sandbox#content-privacy address strong {
  font-weight: 300;
}

.sandbox#content-privacy a.missing-tbc {
  color: red;
  background-color: #fcc;
  outline: dashed 1px #f00;
}